import Status from "../Data/Status.json";

const handleServiceError = (error, setStatus, setErrorDetails) => {
	//if the error is defined in the Status object, set status to this
	if (typeof error === "number") {
		setStatus(error);
	} else if (error?.status) {
		setStatus(error.status)
	}
	else {
		setStatus(Status.UNKNOWN);
	}
	if (error.errorDetails) {
		setErrorDetails?.(error.errorDetails)
	}
};

export default handleServiceError;
