import parse from 'html-react-parser';

//parses new line field, link field, and email field to html
export function parseHtml(text)
{
    if(!text) return text;
    //first new lines
    text = text.replace(/\n/g, "<br/>");
    //second link
    if(text.includes('{LINK')) text = replaceText(text, /{LINK\|([^|]*)\|([^|}]*)}/g, "link");
    //third email
    if(text.includes('{MAILTO')) text = replaceText(text, /{MAILTO\|([^|]*)\|([^|}]*)}/g, "email");
    //then return and parse to html
    return parse(text);
}
function replaceText(text, regex, key)
{
    let index = 0;
    let textAfter = "";
    let result;
    while ((result = regex.exec(text))) {
        const msg = result[1];

        const url = (key === 'email'
            ? 'mailto:' + result[2]
            : (result[2].includes('://')
                ? 'https://' + result[2].split('://')[1]
                : 'https://' + result[2]));

        if (index === 0) {
            textAfter = text.replace(result[0], "<a href='" + url + `'${key === 'link' ? ' target=\'_blank\'' : ''}>` + msg + "</a>");
        } else {
            textAfter = textAfter.replace(result[0], "<a href='" + url + `'${key === 'link' ? ' target=\'_blank\'' : ''}>` + msg + "</a>");
        }
        index++;
    }
    return textAfter;
}