import { useEffect, useMemo, useRef, useState } from "react";
import config from "../config";
import styles from "./useHumanVerification.module.css";
import Turnstile, { useTurnstile } from "react-turnstile";
import { handleTurnstileToken } from "../Utilities/Service";
import LoadingScreen from "../components/LoadingScreen";
import Status from "../Data/Status.json";
import { appInsights } from "../AppInsights";

//description of all turnstile error codes
const ERROR_DESCRIPTIONS = {
	"100***":
		"Initialization problem: Please reload the page and try again. If the problem persists, this may be an automated device.",
	"102***": "Invalid parameter: Please retry the challenge. Continuous failures may indicate an automated device.",
	"103***": "Invalid parameter: Please retry the challenge. Continuous failures may indicate an automated device.",
	"104***": "Invalid parameter: Please retry the challenge. Continuous failures may indicate an automated device.",
	"105***": "API compatibility issue: Please refer to the Turnstile documentation and refresh the page.",
	110100: "Invalid sitekey: Verify if the sitekey provided is still active.",
	110110: "Invalid sitekey: Verify if the sitekey provided is still active.",
	110200: "Domain not allowed: Ensure that the domain is allowed in the widget configuration.",
	110420:
		"Invalid action: Ensure that the action conforms to the specified structure and contains only valid characters.",
	110430:
		"Invalid cData: Ensure that the cData conforms to the specified structure and contains only valid characters.",
	110500: "Unsupported browser: Please upgrade your browser or verify otherwise.",
	110510: "Inconsistent user-agent: Disable any browser extensions or settings that spoof the user agent.",
	110600: "Challenge timed out: Retry the challenge. Ensure your system clock is set correctly.",
	110620: "Challenge outdated: Reset the widget and re-initialize it to retry the challenge.",
	"120***": "Internal error: Encountered by Cloudflare Support Engineers while debugging.",
	200010: "Invalid caching: Clear your browser cache and try again.",
	200100: "Time problem: Set your clock to the correct time.",
	"300***": "Generic client error: Retry the challenge. Continuous failures may indicate an automated device.",
	"600***": "Challenge execution failure: Retry the challenge. Continuous failures may indicate an automated device.",
	default: "An unexpected error occurred. Please try again or contact support.",
};

// Helper function to match error code with patterns
const matchErrorCode = (errorCode, patterns) => {
	if (patterns.has(errorCode)) {
		return errorCode;
	}

	const pattern = [...patterns].find(
		(key) => key.includes("***") && new RegExp(`^${key.replace("***", ".*")}$`).test(errorCode)
	);

	return pattern;
};

//following turnstile recommendations, retry the challenge for these error codes
const RETRY_ERRORS = new Set(["102***", "103***", "104***", "106***", "11060*", "11062*", "300***", "600***"]);
const MAX_CONTINUOUS_ERRORS = 3;

const getErrorDescription = (errorCode) => {
	const matchedPattern = matchErrorCode(errorCode, new Set(Object.keys(ERROR_DESCRIPTIONS)));

	if (matchedPattern) {
		return `${ERROR_DESCRIPTIONS[matchedPattern]} - Turnstile error code ${errorCode}`;
	}

	return `${ERROR_DESCRIPTIONS.default.replace("{error}", errorCode)} - Turnstile error code ${errorCode}`;
};

const TOKEN_REFRESH_INTERVAL = 4 * 60 * 1000; // Refresh token every 4 minutes - the token expires after 5 minutes

const useHumanVerification = ({ shouldUseHumanVerification, setStatus, setErrorDetails, respondent }) => {
	const turnstile = useTurnstile();
	const [isInteractionRequired, setIsInteractionRequired] = useState(false);
	const hasInteractionBeenRequiredRef = useRef(false);
	const [isInitialVerificationComplete, setIsInitialVerificationComplete] = useState(false);
	const turnstileWrapperRef = useRef(null);
	const continuesErrorCountRef = useRef(0);
	const tokenRefreshIntervalRef = useRef(null);
	const [isTurnstileLoaded, setIsTurnstileLoaded] = useState(false);

	// If shouldUseHumanVerification is set to false, set isInitialVerificationComplete to true
	useEffect(() => {
		if (typeof shouldUseHumanVerification === "boolean") {
			if (!shouldUseHumanVerification) {
				setIsInitialVerificationComplete(true);
			}
		}
	}, [shouldUseHumanVerification]);

	// Update the classes for showing/hiding the turnstile + position of the turnstile ui together with the initial loading screen
	useEffect(() => {
		if (!shouldUseHumanVerification || !isTurnstileLoaded) return;

		const displayTurnstile = isInteractionRequired || !isInitialVerificationComplete;
		if (turnstileWrapperRef.current) {
			if (displayTurnstile) {
				if (isInteractionRequired) {
					turnstileWrapperRef.current.classList.add(styles.interaction);
				} else {
					turnstileWrapperRef.current.classList.remove(styles.interaction);
				}

				turnstileWrapperRef.current.classList.add(styles.show);
				turnstileWrapperRef.current.classList.remove(styles.hide);
			} else {
				// Hide the turnstile after a delay to allow the animation to complete
				setTimeout(() => {
					turnstileWrapperRef.current.classList.add(styles.hide);
					turnstileWrapperRef.current.classList.remove(styles.show);
					turnstileWrapperRef.current.classList.remove(styles.interaction);
				}, 1000);
			}
		}
	}, [isInitialVerificationComplete, isInteractionRequired, shouldUseHumanVerification, isTurnstileLoaded]);

	const HumanVerification = useMemo(
		() => () =>
			(
				<>
					{shouldUseHumanVerification && (
						<div className={styles.wrapper} ref={turnstileWrapperRef}>
							<Turnstile
								className={styles.turnstile}
								sitekey={config.REACT_APP_TURNSTILE_SITE_KEY}
								retryInterval={1000}
								onError={(errorCode) => {
									let hasRetried = false;
									// If the error is a retry error, increment  the error count
									if (matchErrorCode(errorCode, RETRY_ERRORS)) {
										hasRetried = true;
										continuesErrorCountRef.current += 1;
										if (continuesErrorCountRef.current < MAX_CONTINUOUS_ERRORS) {
											return;
										}
									}
									const description = getErrorDescription(errorCode);
									setErrorDetails(description);
									setStatus(Status.TURNSTILE);
								
									const campaign = respondent?.campaign ?? respondent;
									const response = respondent?.response;

									//Register to App Insights, that a user has been blocked by Turnstile
									//This will be used to monitor the number of users blocked by Turnstile									
									appInsights.trackEvent({
										name: "TurnstileUserBlocked",
										properties: {
											orgId: campaign?.orgId,
											organizationName: campaign?.organizationName,
											campaignId: campaign?.id,
											campaignMemberId: response?.campaignMemberId,
											campaignMembershipGuid: response?.campaignMembershipGuid,
											turnstileErrorCode: errorCode,
											turnstileErrorDescription: description,
											hasRetried: hasRetried
										}
									});
								}}
								onLoad={() => setIsTurnstileLoaded(true)}
								onBeforeInteractive={() =>{
									//use a ref, to ensure that the loading screen is never shown after an interaction has been required
									//the state may interact with the UI, making the loading screen briefly visible
									hasInteractionBeenRequiredRef.current = true;
									setIsInteractionRequired(true)}}
								onVerify={(token) => {
									// Handle token, set as header for createHeader() in service.js
									handleTurnstileToken(token);

									setIsInteractionRequired(false);
									setIsInitialVerificationComplete(true);

									/**
									 * Ensure that the token is refreshed every 4 minutes to prevent it from expiring
									 */
									// Clear any existing interval
									if (tokenRefreshIntervalRef.current) {
										clearInterval(tokenRefreshIntervalRef.current);
									}

									tokenRefreshIntervalRef.current = setInterval(() => {
										turnstile?.reset();
									}, TOKEN_REFRESH_INTERVAL);

									// Reset the error count
									continuesErrorCountRef.current = 0;
								}}
								autoResetOnExpire={true}
								appearance='always'
							/>
						</div>
					)}
				</>
			),
		[shouldUseHumanVerification, turnstile, setStatus, setErrorDetails, respondent]
	);

	const displayLoadingScreen = !isInitialVerificationComplete && !hasInteractionBeenRequiredRef.current;

	const HumanVerificationLoading = () => {
		return displayLoadingScreen && <LoadingScreen />;
	};

	/**
	 * Turnstile component and Loading compoent, are descalred seperatly,
	 * 	to ensure that Turnstile rendering is not affected by the loading screen.
	 */
	return {
		HumanVerification,
		HumanVerificationLoading,
		isInitialVerificationComplete,
	};
};

export default useHumanVerification;
