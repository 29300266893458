import SurveyInaccessibleType from "../Data/SurveyInaccessibleType.json";
import $ from "jquery";
import * as widgets from "surveyjs-widgets";
import * as SurveyCore from "survey-core";
import "nouislider/distribute/nouislider.min.css";
import "jquery-ui-dist/jquery-ui.css";
import SurveyType from "../Data/SurveyType.json";
require("velocity-animate/velocity.js");
require("jquery-ui-dist/jquery-ui.js");
widgets.jqueryuidatepicker(SurveyCore);
widgets.nouislider(SurveyCore);
window["$"] = window["jQuery"] = $;

// type: detractor, passice, or promoter
const getVisibleOnRating = (ratingMaxValue, type) => {
	let passiveRatingTypeRange = { min: 7, max: 8 };

	if (ratingMaxValue === 5) {
		passiveRatingTypeRange = { min: 3, max: 3 };
	} else if (ratingMaxValue === 10) {
		passiveRatingTypeRange = { min: 7, max: 8 };
	}

	switch (type) {
		case "detractor":
			return `{rating} < ${passiveRatingTypeRange.min}`;
		case "passive":
			return `{rating} >= ${passiveRatingTypeRange.min} && {rating} <= ${passiveRatingTypeRange.max}`;
		case "promoter":
			return `{rating} > ${passiveRatingTypeRange.max}`;
		default:
			return "";
	}
};

/**
 * From an HTML string, return a single line of text
 */
const getInnerTextFromHtmlString = (htmlString) => {
	const formattedHtmlString = htmlString
		// Replace <br> and <br/> tags with a whitespace
		.replace(/<br\s*\/?>/gi, " ")
		// Replace </p> tags with a space only if followed by another HTML tag
		.replace(/<\/p>(?=<\w)/gi, " ");

	// Parse the HTML string
	const parsedDom = new DOMParser().parseFromString(formattedHtmlString, "text/html");

	// Check for parsing errors
	if (parsedDom.documentElement.querySelector("parsererror")) {
		return "ERROR - The title format is invalid";
	}

	return parsedDom.documentElement.textContent;
};

const getLocaleFromCountryName = (countryName) => {
	switch (countryName) {
		case "Dansk":
		case "Danish":
			return "da";
		case "English":
			return "en";
		case "Norsk":
			return "no";
		case "Svenska":
			return "sv";
		case "Deutsch":
			return "de";
		case "русский":
			return "ru";
		case "Latviešu":
			return "lv";
		case "Lietuvos":
			return "lt";
		case "Eesti":
			return "ee";
		case "Føroyskt":
			return "fo";
		case "Kalaallisut":
			return "kl";
		case "Dansk-Kalaallisut":
			return "kl/da";
		case "Nederlands":
			return "nl";
		case "Italiano":
			return "it";
		case "Français":
			return "fr";
		case "Suomi":
			return "fi";
		case "Czech":
			return "cs";
		case "Spanish":
			return "es";
		case "Slovenian":
			return "sl";
		case "Polish":
			return "pl";
		case "Turkish":
			return "tr";
		case "Hungarian":
			return "hu";
		case "Portuguese":
			return "pt";
		case "Catalan":
			return "ca";
		case "Romanian":
			return "ro";
		case "Slovakian":
			return "sk";
		case "Chinese":
			return "zh";
		case "Bulgarian":
			return "bg";
		default:
			return "";
	}
};

function updateDisplayModeForRatings(elements) {
	elements.forEach((element) => {
		if (element.displayMode) return;
		if (element.type === "rating") {
			element.displayMode = "buttons";
		}

		if (element.elements && element.elements.length > 0) {
			updateDisplayModeForRatings(element.elements);
		}
	});
}

export const getModifiedSurveySchema = ({ schema, template }) => {
	schema.locale = schema.locale ?? getLocaleFromCountryName(template.language?.name);

	let ratingMaxValue = 10;
	//find each page
	schema.pages?.forEach((page) => {
		//SET STTYLING ON ALL RATING TYPE ELEMENTS
		updateDisplayModeForRatings(page.elements);

		//MAIN NPS RATING
		const ratingElement = page.elements?.find((element) => element.name === "rating");
		if (ratingElement) {
			ratingElement.isRequired = ratingElement.isRequired ?? true;
			ratingElement.displayMode = ratingElement.displayMode ?? "buttons";
			if (!ratingElement.title) {
				ratingElement.title = getInnerTextFromHtmlString(template.content);
			}
			if (!ratingElement.minRateDescription) {
				ratingElement.minRateDescription = getInnerTextFromHtmlString(template.lowRatingText);
			}
			if (!ratingElement.maxRateDescription) {
				ratingElement.maxRateDescription = getInnerTextFromHtmlString(template.highRatingText);
			}
		}

		//RATING COMMENTS: detractor, passive, and promoter
		const disappointingCommentElement = page.elements?.find((element) => element.name === "disappointing_experience");
		const passiveCommentElement = page.elements?.find((element) => element.name === "passive_experience");
		const satisfiedCommentElement = page.elements?.find((element) => element.name === "satisfied_experience");
		if (ratingElement?.rateMax) {
			ratingMaxValue = ratingElement?.rateMax;
		}

		if (disappointingCommentElement) {
			disappointingCommentElement.visible = disappointingCommentElement.visible ?? false;
			disappointingCommentElement.visibleIf =
				disappointingCommentElement.visibleIf ?? getVisibleOnRating(ratingMaxValue, "detractor");
			if (!disappointingCommentElement.title) {
				disappointingCommentElement.title = template.followUpDetractor;
			} else {
				disappointingCommentElement.title = disappointingCommentElement.title.replace("{MAX_VALUE}", ratingMaxValue);
			}
		}
		if (passiveCommentElement) {
			passiveCommentElement.visible = passiveCommentElement.visible ?? false;
			passiveCommentElement.visibleIf =
				passiveCommentElement.visibleIf ?? getVisibleOnRating(ratingMaxValue, "passive");
			if (!passiveCommentElement.title) {
				passiveCommentElement.title = template.followUpPassive;
			} else {
				passiveCommentElement.title = passiveCommentElement.title.replace("{MAX_VALUE}", ratingMaxValue);
			}
		}
		if (satisfiedCommentElement) {
			satisfiedCommentElement.visible = satisfiedCommentElement.visible ?? false;
			satisfiedCommentElement.visibleIf =
				satisfiedCommentElement.visibleIf ?? getVisibleOnRating(ratingMaxValue, "promoter");
			if (!satisfiedCommentElement.title) {
				satisfiedCommentElement.title = template.followUpPromoter;
			} else {
				satisfiedCommentElement.title = satisfiedCommentElement.title.replace("{MAX_VALUE}", ratingMaxValue);
			}
		}

		//CATEGORY
		const categoryElement = page.elements?.find((element) => element.name === "category");
		if (categoryElement) {
			//if no choices are defined in JSONSchema, use categories from template
			if (!categoryElement.choices) {
				const categories = template.categories;
				if (categories && categories.length > 0) {
					categoryElement.choices = categories.map((category) => {
						return {
							value: category.id,
							text: category.name,
						};
					});
				}
			}
			categoryElement.title = categoryElement.title ?? template.categoryQuestion;
			categoryElement.isRequired = categoryElement.isRequired ?? template.mandatoryCategoryQuestion ?? false;
			categoryElement.visibleIf = categoryElement.visibleIf ?? "{rating} notempty";
		}

		//COMPLETED PAGE
		const outroText = template.outroText;
		if (outroText) {
			schema.completedHtml = schema.completedHtml ?? outroText;
		}

		const includeCustomOutroText = template.includeCustomOutroText;
		if (includeCustomOutroText) {
			const detractorOutroText = template.outroDetractorText;
			const passiveOutroText = template.outroPassiveText;
			const promoterOutroText = template.outroPromoterText;
			schema.completedHtmlOnCondition = schema.completedHtmlOnCondition ?? [
				{
					expression: getVisibleOnRating(ratingMaxValue, "detractor"),
					html: detractorOutroText,
				},
				{
					expression: getVisibleOnRating(ratingMaxValue, "passive"),
					html: passiveOutroText,
				},
				{
					expression: getVisibleOnRating(ratingMaxValue, "promoter"),
					html: promoterOutroText,
				},
			];
		}
	});

	return schema;
};

const getSurveyAnswersSimple = (survey) => {
	const surveyPlainData = survey.getPlainData();
	const surveyAnswersSimple = surveyPlainData.reduce((acc, curr) => {
		const { name, value } = curr;
		acc[name] = { ...curr, value: value !== undefined ? value : null };
		return acc;
	}, {});

	return surveyAnswersSimple;
};

const getSurveyAnswers = (survey) => {
	const surveyPlainData = survey.getPlainData();
	return surveyPlainData.map((answer) => {
		return { ...answer, value: answer.value === undefined ? null : answer.value };
	});
};

/*updates response with the newest survey data*/
export function updateResponse(closedInformation, survey, surveyType, response) {
	if (closedInformation && closedInformation.closed) {
		response.rating = closedInformation.previousRating; //if response is closed, the current rating is irrelevant
		response.closed = true; //set current response closed to true so that response does not re-open
	} else response.rating = survey.data.rating;
	const surveyAnswersSimple = getSurveyAnswersSimple(survey);
	const category = surveyAnswersSimple?.category;
	// comment name is 'comment' for universal campaigns, 'userComment' for all others.

	let commentKey = surveyType === SurveyType.UNIVERSAL ? "comment" : "userComment";
	response.category = category?.value ? { ...category, id: category?.value, name: category?.displayValue } : undefined;
	response[commentKey] =
		survey.data.nps_comment ||
		survey.data.satisfied_experience ||
		survey.data.passive_experience ||
		survey.data.disappointing_experience;
	response.allowedForPublicUse = survey.data.consent;
	response.anon = survey.data.anonymous;
	response.surveyAnswers = getSurveyAnswers(survey);
	response.surveyAnswersSimple = getSurveyAnswersSimple(survey);
}
/*creates response for universal link campaign*/
export function createAnonymousResponse(survey) {
	const surveyAnswersSimple = getSurveyAnswersSimple(survey);
	const category = surveyAnswersSimple?.category;
	return {
		Category: { ...category, id: category?.value, name: category?.displayValue },
		Email: "",
		FirstName: "Anonymous",
		LastName: null,
		Rating: survey.data.rating,
		Comment:
			survey.data.nps_comment ||
			survey.data.satisfied_experience ||
			survey.data.passive_experience ||
			survey.data.disappointing_experience,
		Device: navigator.userAgent,
		SurveyAnswers: getSurveyAnswers(survey),
		SurveyAnswersSimple: surveyAnswersSimple,
		AllowedForPublicUse: survey.data.consent ?? false,
	};
}
/*creates response for popup campaign*/
export function createPopupResponse(survey) {
	const surveyAnswersSimple = getSurveyAnswersSimple(survey);
	const category = surveyAnswersSimple?.category;
	return {
		rating: survey.data.rating,
		category: { ...category, id: category?.value, name: category?.displayValue },
		userComment:
			survey.data.nps_comment ||
			survey.data.satisfied_experience ||
			survey.data.passive_experience ||
			survey.data.disappointing_experience,
		device: navigator.userAgent,
		allowedForPublicUse: survey.data.consent ?? false,
		anon: survey.data.anonymous,
		surveyAnswers: getSurveyAnswers(survey),
		surveyAnswersSimple: surveyAnswersSimple,
	};
}
/*shows a page stating why survey is inaccessible.*/
export function showSurveyInaccessiblePage(survey, type) {
	const name = getKeyByValue(SurveyInaccessibleType, type);
	survey.showNavigationButtons = "none";
	var newPage = survey.pages.filter((p) => {
		return p.name === name; //check if page is already in json
	})[0];
	if (newPage === undefined) {
		//if not then add a simple html page showing the correct message.
		newPage = survey.addNewPage(name);
		// Add a FirstName question to the page
		const htmlElement = newPage.addNewQuestion("html");
		if (type === SurveyInaccessibleType.CAMPAIGN_CLOSED) htmlElement.html = campaignIsClosedHtml;
		else if (type === SurveyInaccessibleType.ALREADY_ANSWERED) htmlElement.html = surveyAlreadyAnsweredHtml;
		else if (type === SurveyInaccessibleType.UNSUBSCRIBED) htmlElement.html = unsubscribedHtml;
		else htmlElement.html = unknownReasonHtml;
	}
	newPage.visible = true;
	survey.currentPage = newPage; //set current page to newpage.
}
/*sets css classes for styling the survey.*/
export function setCssClasses(options) {
	let classes = options.cssClasses;
	classes.withFrame += " contentBox";

	/*
    //set css classes for rating question
    if (options.question.name === "rating") {
      classes.title += " rating-title";
      classes.root += " rating-root"
      classes.item += " rating-group";
    }
    //set css classes for comment question
    if (
      options.question.name === "nps_question" || 
      options.question.name === "disappointed_experience" ||
      options.question.name === "passive_experience" ||
      options.question.name === "satisfied_experience"){
        classes.title += " reason-comment-title";
        classes.root += " reason-comment-root"
      }
      */
}
/*experimental animation using 'velocity-animate'*/
export function animate(animitionType, duration) {
	if (!duration) duration = 1000;

	var element = document.getElementById("surveyElement");
	$(element).velocity(animitionType, { duration: duration });
}
/*
Gets first found key that has to do with a specific value.
Also, sets it to lowercase.
Example: Status.json value 0 = success.
*/
function getKeyByValue(object, value) {
	return Object.keys(object)
		.find((key) => object[key] === value)
		.toLowerCase();
}

const campaignIsClosedHtml = `
    <center>
        <h2>Campaign is closed</h2>
        <p>This survey is no longer accepting responses.</p>
    </center>    
`;

const surveyAlreadyAnsweredHtml = `
    <center>
        <h2>Survey has already been answered</h2>
        <p>This questionnaire has already been answered</p>
    </center>
`;

const unsubscribedHtml = `
    <center>
        <h2>You've unsubscribed</h2>
        <p>You've unsubscribed and will no longer receive surveys from us</p>
    </center>
`;

const unknownReasonHtml = `
    <center>
        <h2>This survey is no longer accessible.</h2>
        <p>It is no longer possible to respond to this survey</p>
    </center>
`;
