import Status from "../Data/Status.json";
import Logo from "../Images/icon64.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import parse from "html-react-parser";
import { useState, useEffect } from "react";
import("../Dependencies/bootstrap-5.2.0.min.css");

function ErrorPage(props) {
	let errorText = "Something went wrong ☹";
	let errorDetails = parse(`This error will only show if we don't know what went wrong. 
  If you keep getting it, contact our support at  <a href='mailto:support@npstoday.com'>support@npstoday.com</a>`);
	if (props.errorDetails) {
		errorDetails = props.errorDetails;
	} else if (props.status === Status.MISSINGGUID) {
		errorText = "Your link is broken ☹";
		errorDetails = `You most likely see this error because the link you clicked on 
    is missing something or you have received the link by mistake.`;
	} else if (props.status === Status.EXPIREDTOKEN) {
		errorText = "Your link has expired";
		errorDetails = "You see this error because the link you were sent has expired.";
	} else if (props.status === Status.NO_INTERNET) {
		errorText = "It seems like you don't have any connection to the internet";
		errorDetails =
			"Please don't refresh your browser, as your progress will be lost. When a connection is established you can continue from where you left off.";
	}

	if (props.status === Status.TURNSTILE) {
		errorText = "Human verification failed";
	}

	const [stylingReady, setStylingReady] = useState(false);

	useEffect(() => {
		async function fixFlickering() {
			try {
				await import("../Dependencies/bootstrap-5.2.0.min.css");
			} finally {
				setStylingReady(true); //prevents flickering at start of survey
			}
		}
		fixFlickering();
	}, []);

	useEffect(() => {
		function handleOnline() {
			//if the user sees the error page due to no internet connection, set status to SUCCESS upon connection, so survey can be completed
			if (props.status === Status.NO_INTERNET) {
				props.setStatus(Status.SUCCESS);
			}
		}

		window.addEventListener("online", handleOnline);

		return () => {
			window.removeEventListener("online", handleOnline);
		};
	}, [props]);

	return (
		<>
			{stylingReady && (
				<Container>
					<br />
					<Row>
						<Col md={6} className='mx-auto text-center'>
							<div className='header-title' style={{ margin: "2rem 0px" }}>
								<img src={Logo} alt='nps.today logo' />
								<h1
									className='wv-heading--title'
									style={{ fontSize: "31px", lineHeight: "40px", fontWeight: 600, color: "#4c5357" }}
								>
									nps.today
								</h1>
								<h2
									className='wv-heading--subtitle'
									style={{ color: "#5e8396", fontSize: "21px", fontWeight: 400, lineHeight: "32px" }}
								>
									<br />
									<span style={{ fontSize: "21px" }}>{errorText}</span>
								</h2>
							</div>
						</Col>
					</Row>

					<br />
					<Row>
						<Col md={4} className='mx-auto'>
							<p style={{ fontSize: "16px" }} id='eDetails'>
								{errorDetails}
							</p>
						</Col>
					</Row>
				</Container>
			)}
		</>
	);
}

export default ErrorPage;
