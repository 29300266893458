import React, { useState, useEffect, useMemo, useRef } from "react";
import Content from "./Content";
import Footer from "./Footer";
import { parseHtml } from "./Helper";
import SurveyType from "../Data/SurveyType.json";
import Service from "../Utilities/Service";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import SurveyHeader from "../NewSurvey/SurveyHeader";
import LoadingScreen from "../components/LoadingScreen";
import handleServiceError from "../Utilities/handleServiceError";
import { PopupEventTypes } from "../Data/PopupEventTypes";
import usePopupSignals from "../Utilities/usePopupSignals";

const getBackgroundColor = () => window.getComputedStyle(document.body).backgroundColor;

const service = new Service().proxy;
function OldSurveyComponent(props) {
	const [stylingReady, setStylingReady] = useState(false);
	const [response, setResponse] = useState({});
	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);
	const [newSection, setNewSection] = useState(false);
	const [notRated, setNotRated] = useState(true);
	const [questionFirst, setQuestionFirst] = useState(false);
	const [finished, setFinished] = useState(false);
	const [unsubscribed, setUnsubscribed] = useState(props.unsubscribedInitially);
	const [allowRatingChange, setAllowRatingChange] = useState(false);
	const [allowPreviousButton, setAllowPreviousButton] = useState(false);
	const [showCategoryRequiredMessage, setShowCategoryRequiredMessage] = useState(false);
	const [isRatingPageRendered, setIsRatingPageRendered] = useState(false);
	const [popupData, setpopupData] = useState({});
	const appInsights = useAppInsightsContext();

	//used for universal link campaigns, after posting the response. If needing to edit it, this ref should hold the new id
	const newAnonymousPublicGuid = useRef('');

	const { signalSurveyCompleted, signalReady, signalSurveyPageChanged } = usePopupSignals({
		enablePopup: props.enablePopup,
		instanceId: props.instanceId,
		getBackgroundColor: getBackgroundColor,
		isLegacySurvey: true,
	});

	const showFooter = useMemo(() => {
		if (props.enablePopup) {
			return false;
		}
		if (unsubscribed) {
			return false;
		}
		return true;
	}, [props.enablePopup, unsubscribed]);

	useEffect(() => {
		if (!stylingReady || !props.enablePopup) {
			return;
		}
		const receiveData = (event) => {
			if (
				event.data.eventType === PopupEventTypes.SET_SURVEY_PARAMETERS &&
				event.data.instanceId === props.instanceId
			) {
				setpopupData(event.data);
			}
		};
		window.addEventListener("message", receiveData, false);

		signalReady();
		return () => {
			window.removeEventListener("message", receiveData);
		};
	}, [props.instanceId, props.enablePopup, stylingReady, signalReady]);

	useEffect(() => {
		const isRated = typeof props.rating === "number" || props.rating?.length > 0;

		async function fixFlickering() {
			await import("../Dependencies/bootstrap-3.3.4-dist/css/bootstrap.min.css");
			await import("./index.css");
			setStylingReady(true); //prevents flickering at start of survey
		}
		fixFlickering();
		appInsights.trackPageView({ name: "OldSurveyComponent.js" });

		if (isRated) setNotRated(false);
		if (props.respondent && props.respondent.response) {
			const copy = props.respondent.response;
			if (isRated && props.allowEditResponse && !props.template.forceSingleSubmit) {
				copy.rating = parseInt(props.rating);
				service.editResponse(copy).catch((e) => handleServiceError(e, props.setStatus, props.setErrorDetails));
				appInsights.trackEvent({ name: "Rated", properties: copy });
			}
			setResponse({ ...copy, rating: props.rating ?? copy.rating });
		}
		if (props.template.questionFirst) setQuestionFirst(true);
		if (props.template.allowRatingChange) setAllowRatingChange(true);
		if (props.surveyType === SurveyType.UNIVERSAL) {
			setResponse({
				email: "",
				firstName: "Anonymous",
				lastName: null,
				rating: props.rating ?? null,
				comment: null,
				answer1: null,
				answer2: null,
				answer3: null,
				answer4: null,
				answer5: null,
				category: null,
				device: navigator.userAgent,
				allowedForPublicUse: response.allowedForPublicUse ?? false
			});
		}
		if (props.template.consentForPublicUse && props.template.consentIsOptOut) {
			setResponse((prevResponse) => {
				return {
					...prevResponse,
					allowedForPublicUse: true,
				};
			});
		}

		const primaryColor = props.template?.colorScheme;
		if (primaryColor) {
			document.querySelector(":root").style.setProperty("--primary-color", primaryColor);
		}
	}, [props, appInsights]);

	async function toggleNextSection() {
		setSending(true);
		if (
			!notRated /*&& !newSection*/ &&
			!props.template.hideCategories &&
			props.template.mandatoryCategoryQuestion &&
			!response.category
		) {
			setShowCategoryRequiredMessage(true);
			setSending(false);
			return;
		}
		try {
			if (props.surveyType !== SurveyType.UNIVERSAL) {
				const closedInformation = await service.responseClosed();
				if (closedInformation && closedInformation.closed) {
					//if response is closed then dont allow changing rating/closed on response
					updateResponse({
						...response,
						rating: closedInformation.previousRating,
						closed: true,
					});
				}
				if (!props.template.forceSingleSubmit) await service.editResponse(response);
			}
			toggleNext();
		} catch (e) {
			handleServiceError(e, props.setStatus, props.setErrorDetails);
		} finally {
			//trackResponseUpdate({ response: response });
			setSending(false);
		}
	}

	function toggleNext() {
		setSent(true);
		setNewSection(true);
		setAllowRatingChange(false);
		if (allowRatingChange) {
			setAllowPreviousButton(true);
		}
		setAllowRatingChange(false);

		if (props.enablePopup) {
			signalSurveyPageChanged();
			return;
		}
	}

	function togglePreviousSection() {
		setAllowRatingChange(true);
		setNewSection(false);
		setSent(false);
	}

	async function sendFinalResponse(onSuccess) {
		setSending(true);
		if (!props.template.hideCategories && props.template.mandatoryCategoryQuestion && !response.category) {
			setShowCategoryRequiredMessage(true);
			setSending(false);
			return;
		}
		if (props.enablePopup) {
			try {
				const surveyResponse = {
					...response,
					userComment: response.comment,
				};
				await service.addPopupResponse(surveyResponse, popupData.campaignMember);
				setFinished(true);
				setAllowRatingChange(false);
				setNewSection(false);
				onSuccess?.();
				signalSurveyCompleted();
			} catch (e) {
				handleServiceError(e, props.setStatus, props.setErrorDetails);
			} finally {
				setSending(false);
				return;
			}
		}

		try {
			if (props.surveyType === SurveyType.UNIVERSAL) {
				if(!newAnonymousPublicGuid.current){
					newAnonymousPublicGuid.current = await service.addRespondentAndResponse(response);
				} else {
					await service.updatePublicConsentOnAnonymousCampaignMember(response, newAnonymousPublicGuid.current);
				}
			}
			else await service.addFinalResponse(response);
			setFinished(true);
			setAllowRatingChange(false);
			setNewSection(false);
			onSuccess?.();
		} catch (e) {
			handleServiceError(e, props.setStatus, props.setErrorDetails);
		} finally {
			setSending(false);
		}
	}

	async function rate(rating) {
		const updatedResponse = { ...response, rating: rating };
		setResponse(updatedResponse);
		if (props.surveyType !== SurveyType.UNIVERSAL && !props.template.forceSingleSubmit) {
			setSending(true);
			await service
				.editResponse(updatedResponse)
				.then(() => {
					setNotRated(false);
				})
				.finally(() => {
					setSending(false);
				})
				.catch((e) => {
					handleServiceError(e, props.setStatus, props.setErrorDetails);
				});
		} else {
			setNotRated(false);
		}
	}

	function updateResponse(key, value) {
		setResponse({ ...response, [key]: value });
	}

	function setMultipleChoice(e, answerKey, choice) {
		const questionKey = "question" + answerKey.slice(-1);
		const question = JSON.parse(props.template[questionKey]);
		//if radio
		if (!question.allowMultipleAnswers) {
			setResponse({
				...response,
				[answerKey]: JSON.stringify({
					answer: [choice],
					question: question.question,
				}),
			});
			return;
		}
		//if checkbox
		const checked = e.target.checked;
		let copy = JSON.parse(response[answerKey]);
		//if no previous answer, create the first one
		if (copy === null) {
			setResponse({
				...response,
				[answerKey]: JSON.stringify({
					answer: [choice],
					question: question.question,
				}),
			});
			return;
		}
		//if checked add to answer list
		if (checked) copy.answer.push(choice);
		//else remove from answer list
		else copy.answer = copy.answer.filter((a) => a !== choice);
		//update state with the edited copy
		setResponse({ ...response, [answerKey]: JSON.stringify(copy) });
	}

	return (
		<>
			{(!stylingReady || !props.template) && <LoadingScreen />}
			{props.template && stylingReady && (
				<div
					className='comment-wrapper comment-wrapper-desktop comment-wrapper-mobile'
					style={{
						textAlign: props.template.fontFamily
							? props.template.fontFamily + ", Roboto, Arial, sans-seriff"
							: "Roboto, Arial, sans-seriff",
					}}
				>
					{!props.enablePopup && <SurveyHeader template={props.template} />}
					{isRatingPageRendered && props.surveyType === SurveyType.UNIVERSAL && props.template.introText && (
						<div className='intro-text'>{parseHtml(props.template.introText)}</div>
					)}
					<Content
						notRated={notRated}
						allowPreviousButton={allowPreviousButton}
						allowRatingChange={allowRatingChange}
						sent={sent}
						sending={sending}
						finished={finished}
						newSection={newSection}
						questionFirst={questionFirst}
						unsubscribed={unsubscribed}
						template={props.template}
						response={response}
						respondent={props.respondent}
						rate={rate}
						surveyType={props.surveyType}
						toggleNextSection={toggleNextSection}
						togglePreviousSection={togglePreviousSection}
						updateResponse={updateResponse}
						setMultipleChoice={setMultipleChoice}
						sendFinalResponse={sendFinalResponse}
						showCategoryRequiredMessage={showCategoryRequiredMessage}
						setIsRatingPageRendered={setIsRatingPageRendered}
					/>
					{showFooter && (
						<Footer
							template={props.template}
							surveyType={props.surveyType}
							respondent={props.respondent}
							service={service}
							setUnsubscribed={setUnsubscribed}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default OldSurveyComponent;
