import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown, faSmile } from "@fortawesome/free-regular-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { parseHtml } from "../Helper";
import ColorSchemes from "../../Data/ColorSchemes.json";
import LoadingIndicator from "../../components/LoadingIndicator";

function RatingPage(props) {
	const appInsights = useAppInsightsContext();
	const setIsRatingPageRenderedRef = useRef(props.setIsRatingPageRendered);
	useEffect(() => {
		const setIsRendered = setIsRatingPageRenderedRef.current;
		setIsRendered(true);
		appInsights.trackPageView({ name: "RatingPage.js" });
		return () => {
			setIsRendered(false);
		};
	}, [appInsights]);
	return (
		<>
			<div className={`rating-container body`}>
				<div className='question-container'>
					<h2 className='question'>{parseHtml(props.template.content)}</h2>
				</div>
				<br />
				<div className='rating'>
					{props.ratings &&
						props.ratings.map((r, key) => {
							return (
								<a
									href='#/'
									key={key}
									onClick={() => {
										if (!props.sending) {
											props.rate(r);
											appInsights.trackEvent({ name: "Rated", properties: props.response });
										}
									}}
								>
									<div
										className='button-link'
										style={
											props.rating === r
												? { backgroundColor: "", borderColor: props.template.colorScheme }
												: {
														backgroundColor:
															props.template.htmlTemplateId === 1
																? (r < 7 && ColorSchemes.DETRACTOR) ||
																  ((r === 7 || r === 8) && ColorSchemes.PASSIVE) ||
																  (r > 8 && ColorSchemes.PROMOTER)
																: props.template.colorScheme,
												  }
										}
									>
										<p
											className='rating-figure'
											style={props.rating === r ? { color: props.template.colorScheme } : {}}
										>
											{r}
										</p>
										{props.rating === r && props.sending && props.notRated && (
											<div className='loading-indicator-wrapper' style={{ color: props.template.colorScheme }}>
												<LoadingIndicator />
											</div>
										)}
									</div>
								</a>
							);
						})}
				</div>
				{/* <br /> */}
				<div className='likeliness clearfix'>
					<div style={{ float: "left", textAlign: "left" }}>
						<p>{parseHtml(props.template.lowRatingText)}</p>
						{props.template.enableSmiley && <FontAwesomeIcon icon={faFrown} size='3x' color='black' />}
					</div>
					<div style={{ float: "right", textAlign: "right" }}>
						<p>{parseHtml(props.template.highRatingText)}</p>
						{props.template.enableSmiley && <FontAwesomeIcon icon={faSmile} size='3x' color='black' />}
					</div>
				</div>
			</div>
		</>
	);
}

export default RatingPage;
