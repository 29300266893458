const CheckboxRadioInput = ({ type, name, index, question, onClick, defaultChecked }) => {
	const inputId = `${name}-${index}`;

	return (
		<div className='checkboxRadio'>
			<input id={inputId} name={name} onClick={onClick} type={type} defaultChecked={defaultChecked} />
			<label htmlFor={inputId}>{question}</label>
		</div>
	);
};

export default CheckboxRadioInput;
