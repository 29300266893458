import React, { useState, useEffect } from "react";
import NewSurveyComponent from "./NewSurvey/SurveyComponent";
import OldSurveyComponent from "./OldSurvey/OldSurveyComponent";
import Service from "./Utilities/Service";
import SurveyType from "./Data/SurveyType.json";
import Status from "./Data/Status.json";
import Theme from "./Data/Theme.json";
import ErrorPage from "./Error/ErrorPage";
import { StylesManager } from "survey-core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "./AppInsights";
import LoadingScreen from "./components/LoadingScreen";
import handleServiceError from "./Utilities/handleServiceError";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import Languages from "./Data/Languages.json";
import useHumanVerification from "./HumanVerification/useHumanVerification";

appInsights.trackPageView({ name: "App.js" });
const queryParams = new URLSearchParams(window.location.search);
const rating = queryParams.get("rating") ? Number(queryParams.get("rating")) : undefined;
const campaignMemberPublicGuid = queryParams.get("id");
const sha = queryParams.get("sha");
const campaign = queryParams.get("campaign");
const unsubscribe = Boolean(queryParams.get("unsubscribe"));
const enablePopup = Boolean(queryParams.get("enablePopup"));
const instanceId = queryParams.get("instanceId");

let surveyType = SurveyType.NORMAL;
if (sha) surveyType = SurveyType.UNIQUE;
else if (campaign) surveyType = SurveyType.UNIVERSAL;

function App() {
	const [respondent, setRespondent] = useState(null);
	const [template, setTemplate] = useState(null);
	const [status, setStatus] = useState(Status.SUCCESS);
	const [errorDetails, setErrorDetails] = useState("");
	const [surveySchema, setSurveySchema] = useState(null);
	const [surveyTheme, setSurveyTheme] = useState(null);
	const [surveyDoAnimation, setSurveyDoAnimation] = useState(null);
	const [allowEditResponse, setAllowEditResponse] = useState(true);
	const [unsubscribedInitially, setUnsubscribedInitially] = useState(false);
	const [enableHumanVerification, setEnableHumanVerification] = useState(undefined);

	const { HumanVerification, HumanVerificationLoading, isInitialVerificationComplete } = useHumanVerification({
		shouldUseHumanVerification: enableHumanVerification,
		setStatus: setStatus,
		setErrorDetails: setErrorDetails,
		respondent: respondent
	});

	const applySurveyType = (template) => {
		if (template.surveySchema) {
			let schema =
				typeof template.surveySchema === "string" ? JSON.parse(template.surveySchema) : template.surveySchema;

			StylesManager.applyTheme(schema.theme ?? "defaultV2");
			setSurveyTheme(Theme[schema.theme ?? "defaultV2"]);
			setSurveySchema(schema);
			setSurveyDoAnimation(schema.animation ?? false);
		}

		//if language on template is not supported by frontend, set language to english
		const isTemplateLanguageAvailable = Boolean(Languages["SUBMIT"]?.[template?.language?.name]);
		const updatedTemplate = {
			...template,
			language: {
				...template.language,
				name: isTemplateLanguageAvailable ? template?.language?.name : "English",
			},
		};
		setTemplate(updatedTemplate);
	};

	useEffect(() => {
		const prepareSurvey = async () => {
			const service = new Service().proxy;

			const handleInitalUnsubscribe = async () => {
				if (unsubscribe) {
					await service.unsubscribe();
					setUnsubscribedInitially(true);
				}
			};

			switch (surveyType) {
				case SurveyType.NORMAL:
					if (!campaignMemberPublicGuid) {
						handleServiceError(Status.MISSINGGUID, setStatus, setErrorDetails);
						return;
					}
					service
						.getResponse()
						.then(async (r) => {
							if (r.response.closed || !r.campaign.acceptingResponses) setAllowEditResponse(false);
							await handleInitalUnsubscribe();
							setRespondent(r);
							const template = r.campaign.template;
							setEnableHumanVerification(r.enableHumanVerification);
							applySurveyType(template);
						})
						.catch(() => {
							handleServiceError(Status.UNKNOWN, setStatus, setErrorDetails);
						});
					break;
				case SurveyType.UNIQUE:
					if (!sha) {
						handleServiceError(Status.MISSINGGUID, setStatus, setErrorDetails);
						return;
					}
					service
						.addRespondentPersonalizedLink()
						.then(() => {
							service
								.getResponse()
								.then(async (r) => {
									if (r.response.closed || !r.campaign.acceptingResponses) setAllowEditResponse(false);
									await handleInitalUnsubscribe();
									setRespondent(r);
									const template = r.campaign.template;
									setEnableHumanVerification(r.enableHumanVerification);
									applySurveyType(template);
								})
								.catch((e) => {
									handleServiceError(Status.UNKNOWN, setStatus, setErrorDetails);
								});
						})
						.catch((e) => {
							handleServiceError(Status.UNKNOWN, setStatus, setErrorDetails);
						});
					break;
				case SurveyType.UNIVERSAL:
					if (!campaign) {
						handleServiceError(Status.MISSINGGUID, setStatus, setErrorDetails);
						return;
					}
					service
						.getCampaignInfo()
						.then((r) => {
							setRespondent(r);
							const template = r.template;
							setEnableHumanVerification(r.enableHumanVerification);
							applySurveyType(template);
						})
						.catch(() => {
							handleServiceError(Status.UNKNOWN, setStatus, setErrorDetails);
						});
					break;
				default:
					handleServiceError(Status.UNKNOWN, setStatus, setErrorDetails);
			}
		};

		prepareSurvey();
	}, []);

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<ThemeProvider theme={theme}>
				{status !== Status.SUCCESS ? (
					<ErrorPage status={status} setStatus={setStatus} errorDetails={errorDetails} />
				) : (
					<>
						<HumanVerificationLoading />
						<HumanVerification />
						{/**
						 * If the initial human verification is not complete, show the loading screen
						 * If isInteractionRequired, dont show loading, as the human verification component is shown
						 *
						 * If the initial human verification is complete, show the survey component - which also triggers any initial saving of responses
						 */}

						{isInitialVerificationComplete && (
							<>
								{respondent && template && template.surveySchema && status === Status.SUCCESS && (
									<NewSurveyComponent
										respondent={respondent ?? null}
										template={template ?? null}
										surveyType={surveyType}
										rating={rating}
										acceptingResponses={
											respondent && (respondent.acceptingResponses ?? respondent.campaign.acceptingResponses)
										}
										campaignMemberPublicGuid={campaignMemberPublicGuid ?? null}
										sha={sha ?? null}
										campaign={campaign ?? null}
										surveyTheme={surveyTheme}
										surveySchema={surveySchema}
										surveyDoAnimation={surveyDoAnimation}
										setSurveyDoAnimation={setSurveyDoAnimation}
										setStatus={setStatus}
										allowEditResponse={allowEditResponse}
										unsubscribedInitially={unsubscribedInitially}
										enablePopup={enablePopup}
										instanceId={instanceId}
										setErrorDetails={setErrorDetails}
									/>
								)}
								{respondent && template && !template.surveySchema && status === Status.SUCCESS && (
									<OldSurveyComponent
										respondent={respondent ?? null}
										template={template ?? null}
										surveyType={surveyType}
										rating={rating}
										acceptingResponses={
											respondent && (respondent.acceptingResponses ?? respondent.campaign.acceptingResponses)
										}
										campaignMemberPublicGuid={campaignMemberPublicGuid ?? null}
										sha={sha ?? null}
										campaign={campaign ?? null}
										setStatus={setStatus}
										allowEditResponse={allowEditResponse}
										unsubscribedInitially={unsubscribedInitially}
										enablePopup={enablePopup}
										instanceId={instanceId}
										setErrorDetails={setErrorDetails}
									/>
								)}
								{!(respondent && template) && <LoadingScreen />}
							</>
						)}
					</>
				)}
			</ThemeProvider>
		</AppInsightsContext.Provider>
	);
}

export default withAITracking(reactPlugin, App);
