/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import languages from "../Data/Languages.json";
import SurveyType from "../Data/SurveyType.json";
import { parseHtml } from "./Helper";
import FooterContent from "../components/FooterContent";

function Footer(props) {
	const language = props.template.language.name;
	const organizationName = props.respondent.organizationName || props.respondent.companyName;

	return (
		<>
			<div style={{height: "1rem"}}/>
			<div className='info-text'>
				<FooterContent htmlString={props.template.footerContent} />
				&nbsp;
				<span>
					{organizationName ? (
						<>
							{parseHtml(languages.RUN_BY[language])}&nbsp;<span>{organizationName}.</span>
						</>
					) : (
						<>{parseHtml(languages.RUN_BY_COMPANY[language])}.</>
						)}
				</span>
				{props.surveyType !== SurveyType.UNIVERSAL && props.template.enableUnsubscribe && (
					<>
						&nbsp;
						<a
							href={"#"}
							onClick={async () => {
								await props.service.unsubscribe();
								props.setUnsubscribed(true);
							}}
							style={{ color: "#b5b5b5" }}
							>
							<span>{languages.UNSUBSCRIBE[language]}</span>
						</a>
					</>
				)}
			</div>
		</>
	);
}

export default Footer;
