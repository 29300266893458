import React, { useEffect } from "react";
import Languages from "../../Data/Languages.json";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
function CampaignClosedPage(props) {
	const appInsights = useAppInsightsContext();
	useEffect(() => {
		appInsights.trackPageView({ name: "CampaignClosedPage.js" });
	}, [appInsights]);
	const language = props.template.language.name;
	return (
		<>
			<div className={"body"}>
				<h2>{Languages.ACCEPTING_RESPONSES[language]}</h2>
			</div>
		</>
	);
}

export default CampaignClosedPage;
