import config from "../config";
import Status from "../Data/Status.json";

let baseUrl = config.REACT_APP_API_URL;
const queryParams = new URLSearchParams(window.location.search);
const campaignUniversalLinkId = queryParams.get("campaign");
const campaignId = parseInt(queryParams.get("cid"));
const campaignMemberPublicGuid = queryParams.get("id");
const sha = queryParams.get("sha");
const email = queryParams.get("email");
const name = queryParams.get("name");
const firstName = queryParams.get("firstName");
const lastName = queryParams.get("lastName");
const ext = queryParams.get("ext");
const employee = queryParams.get("employee");

export default class Service {
	constructor() {
		// class constructor code
		this.proxy = new Proxy(this, {
			get: (target, key) => {
				if (typeof target[key] === "function") {
					return async (...args) => {
						if (!window.navigator.onLine) {
							return Promise.reject(Status.NO_INTERNET);
						}
						return target[key](...args);
					};
				}
				return target[key];
			},
		});
	}

	//creates campaignmember based on unique survey link.
	async addRespondentPersonalizedLink() {
		const reservedQueryParams = [
			"campaign",
			"cid",
			"id",
			"sha",
			"email",
			"name",
			"firstName",
			"lastName",
			"ext",
			"phone",
			"rating",
			"employee",
			"unsubscribe",
		];
		const dynamicQueryParams = ["unsubscribe"];

		const custom = {};
		//any params given that aren't possible keys will have their values set in custom.
		for (const key of queryParams.keys()) {
			if (!reservedQueryParams.includes(key)) {
				custom[key] = queryParams.get(key);
			}
		}
		let phoneNumber = "";
		const prefixForHash = window.location.search
			.substring(1)
			.split("&")
			.map((e) => {
				const key = e.split("=")[0];
				if (reservedQueryParams.includes(key) && !dynamicQueryParams.includes(key)) {
					const value = e.split("=")[1];
					if (key === "phone") phoneNumber = decodeURI(value);
					if (key === "sha" || key === "rating") return "";
					else return decodeURI(value);
				}
				return "";
			})
			.join("");

		const respondent = [
			{
				respondent: {
					emailAddress: email ?? `${campaignMemberPublicGuid}@nps.today`,
					firstName: firstName ?? name ?? "",
					lastName: lastName ?? "",
					phoneNumber: phoneNumber ?? "",
					externalId: ext === null || ext === "" ? "" : ext,
				},
				employee: { email: employee },
				guid: campaignMemberPublicGuid,
				custom: Object.keys(custom).length === 0 ? undefined : custom,
			},
		];
		const header = createHeader("post", respondent);
		await callEndpoint(
			"/campaigns/organization/" + campaignId + "?prefixAndSha=" + encodeURIComponent(prefixForHash + "|" + sha),
			header
		);
	}
	//creates campaignmember and response simultaneously. Is used for universal links.
	async addRespondentAndResponse(response) {
		const header = createHeader("post", response);
		const postResponse = await callEndpoint("/anonymous/" + campaignUniversalLinkId + "/respondent", header);

		const newPublicGuid = await postResponse.text();
		return newPublicGuid
	}

	async updatePublicConsentOnAnonymousCampaignMember(response, publicGuid) {
		const header = createHeader("put", response);
		await callEndpoint("/anonymous/" + campaignUniversalLinkId + "/respondent/" + publicGuid, header);
	}

	// creates a response based on data sent to the survey from the popup window. Should only be used if the enablePopup query param is set to true
	async addPopupResponse(response, popopCampaignMemberData = {}) {
		//some data may be relevant to add to the response directly from the popop, fx anon: true
		const responsePopupData = popopCampaignMemberData?.response ?? {};

		//custom data is stored as a stringified json object in the popup, be we should accept JSON or string
		let customPopupData = undefined;
		try {
			customPopupData = JSON.stringify(popopCampaignMemberData.custom);
		} catch (e) {
			customPopupData = popopCampaignMemberData.custom;
		}

		const popupResponse = {
			...popopCampaignMemberData,
			custom: customPopupData,
			response: {
				...response,
				...responsePopupData,
			},
		};

		const header = createHeader("post", popupResponse);
		const campaign = await this.getCampaignInfo();
		await callEndpoint("/popup/survey/" + campaign.publicId, header);
	}
	//checks if response has been closed so that it cannot be reopened which would result in unexpected behaviours.
	async responseClosed() {
		const res = await callEndpoint("/responses/closed?id=" + campaignMemberPublicGuid);
		if (res.status === 204) return null;
		const json = await res.json();
		return json;
	}
	//updates the response to it's final state. Is used when final submit button is clicked.
	async addFinalResponse(response) {
		const header = createHeader("put", response);
		const res = await callEndpoint(
			"/responses/" + campaignMemberPublicGuid + "?emailAnswer=true&skipAlertQueue=true",
			header
		);
		const json = await res.json();
		return json;
	}
	//gets response information and more (template, campaign info, etc.)
	async getResponse() {
		const res = await callEndpoint("/responses/" + campaignMemberPublicGuid + "?mergeTextFields=true");
		const json = await res.json();
		return json;
	}
	//updates response. Is used in various events in the survey.
	async editResponse(response) {
		const header = createHeader("put", response);
		const res = await callEndpoint("/responses/" + campaignMemberPublicGuid + "?emailAnswer=true", header);

		//403 is most likely due to BOT answer being previously detected
		//if forbidden error, return the response from params, as fetch returned no response (if not returning, application runs into error)
		if (res?.status === 403) {
			return response;
		}

		const json = await res.json();
		return json;
	}
	//gets campaign info. Is only used in universal links.
	async getCampaignInfo() {
		const res = await callEndpoint("/anonymous/" + campaignUniversalLinkId);
		const json = await res.json();
		return json;
	}
	//unsubscribes the respondent from the organization. Is used if the respondent wishes not to get surveys again.
	async unsubscribe() {
		const header = createHeader("put");
		await callEndpoint("/respondents/" + campaignMemberPublicGuid + "/unsubscribe", header);
	}
}

//helper function for creating header
let turnstileToken = undefined;
export const handleTurnstileToken = (token) => {
	turnstileToken = token;
};
function createHeader(method, body) {
	const headers = {
		"Content-Type": "application/json",
	};

	if (turnstileToken) {
		headers["turnstile-token"] = turnstileToken;
	}

	return {
		method: method,
		body: body ? JSON.stringify(body) : undefined,
		headers: {
			...headers,
		},
	};
}

class EndpointError extends Error {
	constructor(status, errorDetails) {
		super(errorDetails);
		this.status = status;
		this.errorDetails = errorDetails;
	}
}

// helper function for catching failues when the server doesn't throw an error but the request is not successful
const callEndpoint = async (endpoint, header) => {
	const response = await fetch(baseUrl + endpoint, header);

	const newTurnstileToken = response.headers.get("turnstile-token");
	if(newTurnstileToken){
		handleTurnstileToken(newTurnstileToken);
	}

	if (!response.ok) {
		const json = await response
			.json()
			.then((j) => {
				return j;
			})
			.catch((error) => {
				// silently catch any serialization errors
				// avoids ending up with an unknown error before getting a chance to inspect the status of the response
			});
		const errorList = Object.values(json?.errors ?? "").join("\n");

		// Handle Turnstile token error
		if (response.status === 401 && json?.message?.includes("turnstile")) {
			throw new EndpointError(Status.TURNSTILE, json?.message);
		}

		if (response.status === 400) {
			throw new EndpointError(Status.BAD_REQUEST, errorList);
		}
		throw new EndpointError(Status.UNKNOWN, errorList);
	}
	return response;
};
