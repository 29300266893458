import { parseHtml } from "../OldSurvey/Helper";

const FooterContent = ({ htmlString }) => {
	return (
		<>
			{htmlString && (
				<>
					{parseHtml(htmlString)} <br />
				</>
			)}
		</>
	);
};

export default FooterContent;
