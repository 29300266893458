import { useCallback, useEffect } from "react";
import {
	signalSurveyCompleted,
	signalReady,
	signalSurveyHeightChanged,
	signalSurveyPageChanged,
} from "../Utilities/messageHandler";

const usePopupSignals = ({ enablePopup, instanceId, getBackgroundColor, isLegacySurvey = false }) => {
	// Setup resize observer for popup survey - use method for ref, to ensure that the observer is attached when the ref is populated
	const attachSurveyContentRef = useCallback(
		(node) => {
			if (node && enablePopup) {
				const observer = new ResizeObserver((entries) => {
					for (const entry of entries) {
						const newHeight = entry.borderBoxSize[0].blockSize;
						signalSurveyHeightChanged(instanceId, newHeight);
					}
				});
				observer.observe(node);

				// Return cleanup function
				return () => {
					observer.disconnect();
				};
			}
		},
		[enablePopup, instanceId]
	);

	// Setup resize observer for legacy survey
	useEffect(() => {
		if (!isLegacySurvey || !enablePopup) return;
		const targetElement = document.getElementById("survey-body");
		const observer = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const newHeight = entry.borderBoxSize[0].blockSize;
				signalSurveyHeightChanged(instanceId, newHeight);
			}
		});
		observer.observe(targetElement);
		return () => {
			observer.disconnect();
		};
	}, [isLegacySurvey, enablePopup, instanceId]);

	const signalSurveyCompletedCallback = useCallback(() => {
		return enablePopup && signalSurveyCompleted(instanceId);
	}, [enablePopup, instanceId]);

	const signalReadyCallback = useCallback(() => {
		return enablePopup && signalReady(instanceId, getBackgroundColor?.());
	}, [enablePopup, instanceId, getBackgroundColor]);

	const signalSurveyPageChangedCallback = useCallback(() => {
		return enablePopup && signalSurveyPageChanged(instanceId, getBackgroundColor?.());
	}, [enablePopup, instanceId, getBackgroundColor]);

	return {
		signalSurveyCompleted: signalSurveyCompletedCallback,
		signalReady: signalReadyCallback,
		signalSurveyPageChanged: signalSurveyPageChangedCallback,
		attachSurveyContentRef,
	};
};

export default usePopupSignals;
