import React from "react";
import CommentPage from "./Pages/CommentPage";
import RatingPage from "./Pages/RatingPage";
import FollowUpQuestionsPage from "./Pages/FollowUpQuestionsPage";
import OutroPage from "./Pages/OutroPage";
import CampaignClosedPage from "./Pages/CampaignClosedPage";
import AlreadyAnsweredPage from "./Pages/AlreadyAnsweredPage";
import UnsubscribedPage from "./Pages/UnsubscribedPage";

function Content(props) {
	const ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const acceptingResponses = (props.respondent.campaign && props.respondent.campaign.acceptingResponses) ?? true;
	const originalResponseWasClosed = (props.respondent.response && props.respondent.response.closed) ?? false;
	const alreadyCommented =
		props.respondent.response && (props.respondent.response.userComment || props.respondent.response.commentTime);

	function GetFollowUpQuestionsPage() {
		return (
			<FollowUpQuestionsPage
				ratings={ratings}
				template={props.template}
				response={props.response}
				updateResponse={props.updateResponse}
				setMultipleChoice={props.setMultipleChoice}
				toggleNextSection={props.toggleNextSection}
				sendFinalResponse={props.sendFinalResponse}
				sending={props.sending}
				questionFirst={props.questionFirst}
				togglePreviousSection={props.togglePreviousSection}
				allowPreviousButton={props.allowPreviousButton}
				allowRatingChange={props.allowRatingChange}
			/>
		);
	}

	function GetOutroPage() {
		return (
			<OutroPage
				template={props.template}
				response={props.response}
				updateResponse={props.updateResponse}
				sendFinalResponse={props.sendFinalResponse}
				sending={props.sending}
			/>
		);
	}
	function GetRatingPage() {
		return (
			<RatingPage
				notRated={props.notRated}
				rating={props.response.rating}
				ratings={ratings}
				template={props.template}
				rate={props.rate}
				sending={props.sending}
				allowRatingChange={props.allowRatingChange}
				setIsRatingPageRendered={props.setIsRatingPageRendered}
			/>
		);
	}
	function GetCommentPage() {
		return (
			<CommentPage
				allowRatingChange={props.allowRatingChange}
				toggleNextSection={props.toggleNextSection}
				template={props.template}
				response={props.response}
				updateResponse={props.updateResponse}
				sendFinalResponse={props.sendFinalResponse}
				surveyType={props.surveyType}
				showCategoryRequiredMessage={props.showCategoryRequiredMessage}
				sending={props.sending}
				questionFirst={props.questionFirst}
				getRatingPage={GetRatingPage}
			/>
		);
	}
	function GetUnsubscribedPage() {
		return <UnsubscribedPage template={props.template} />;
	}

	return (
		<div>
			{/* show campaign closed page if acceptingResponses is false */}
			{!acceptingResponses ? (
				<CampaignClosedPage template={props.template} />
			) : // show already answered page if response was closed at start of survey,
			// or if comment/comment time has a value.
			originalResponseWasClosed || alreadyCommented ? (
				<AlreadyAnsweredPage template={props.template} />
			) : props.unsubscribed ? (
				GetUnsubscribedPage()
			) : (
				<>
					{/* normal survey sequence: rate first, then comment, then extra questions, then outro page. */}
					{!props.questionFirst && (
						<>
							{props.notRated && GetRatingPage()}
							{!props.notRated &&
								(!props.finished
									? !props.newSection
										? GetCommentPage()
										: GetFollowUpQuestionsPage()
									: GetOutroPage())}
						</>
					)}
					{/* inverse survey sequence: extra questions first, then rate, then comment, then outro page. */}
					{props.questionFirst && (
						<>
							{!props.newSection && !props.finished && GetFollowUpQuestionsPage()}
							{props.notRated && props.newSection && GetRatingPage()}
							{!props.notRated && (props.newSection ? GetCommentPage() : GetOutroPage())}
						</>
					)}
				</>
			)}
		</div>
	);
}

export default Content;
