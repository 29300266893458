import { PopupEventTypes } from "../Data/PopupEventTypes";

export const signalReady = (instanceId, backgroundColor) => {
	window.parent.postMessage(
		{ eventType: PopupEventTypes.SURVEY_READY, instanceId: instanceId, backgroundColor: backgroundColor },
		"*"
	);
};

export const signalSurveyCompleted = (instanceId) => {
	window.parent.postMessage({ eventType: PopupEventTypes.SURVEY_COMPLETED, instanceId: instanceId }, "*");
};

export const signalSurveyHeightChanged = (instanceId, newHeight) => {
	window.parent.postMessage(
		{ eventType: PopupEventTypes.SURVEY_HEIGHT_CHANGED, instanceId: instanceId, newHeight: newHeight },
		"*"
	);
};

export const signalSurveyPageChanged = (instanceId) => {
	window.parent.postMessage({ eventType: PopupEventTypes.SURVEY_PAGE_CHANGED, instanceId: instanceId }, "*");
};
