import React, { useEffect } from "react";
import { parseHtml } from "../Helper";
import languages from "../../Data/Languages.json";
import SurveyType from "../../Data/SurveyType.json";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import NextButton from "../NextButton";
import CheckboxRadioInput from "../CheckboxRadioInput";
function CommentPage(props) {
	const appInsights = useAppInsightsContext();
	useEffect(() => {
		appInsights.trackPageView({ name: "CommentPage.js" });
	}, [appInsights]);
	const language = props.template.language.name;
	function setFollowUpText() {
		const rating = props.response.rating;
		if (rating < 7) return props.template.followUpDetractor;
		if (rating === 7 || rating === 8) return props.template.followUpPassive;
		if (rating > 8) return props.template.followUpPromoter;
	}
	return (
		<>
			{props.allowRatingChange && props.getRatingPage()}
			<div className={"body"} style={props.allowRatingChange ? { marginTop: "10px" } : undefined}>
				<h2 className='question'>{setFollowUpText()}</h2>
				<textarea
					rows='4'
					placeholder={languages.COMMENT_PLACEHOLDER[language]}
					onChange={(e) => {
						props.updateResponse(props.surveyType === SurveyType.UNIVERSAL ? "comment" : "userComment", e.target.value);
					}}
				></textarea>
				{!props.template.hideCategories && (
					<>
						<h4>{props.template.categoryQuestion}</h4>
						{props.showCategoryRequiredMessage && (
							<span style={{ color: "red", fontFamily: "Verdana,sans-serif", fontStyle: "italic" }}>
								{languages.REQUIRED_FIELD[language]}
							</span>
						)}
						<div className='categories'>
							{props.template.categories.map((c, key) => {
								return (
									<div
										key={key}
										style={{ background: c === props.response.category ? props.template.colorScheme : "" }}
										onClick={() => props.updateResponse("category", c)}
										className={c === props.response.category ? "category selected" : "category"}
									>
										{c.name}
									</div>
								);
							})}
						</div>
					</>
				)}

				<br />
				{/* Universal campaign are always anonymous */}
				{props.surveyType !== SurveyType.UNIVERSAL && !props.template.hideAnonymity && (
					<>
						<div className='anonymity'>
							<CheckboxRadioInput
								type='checkbox'
								defaultChecked={props.response.anon}
								name='anonymityCheckbox'
								question={parseHtml(languages.ANONYMOUS[language])}
								onClick={() => props.updateResponse("anon", !props.response.anon)}
							/>
						</div>
						<br />
					</>
				)}
				{props.template.consentForPublicUse && props.template.consentForPublicUsePlacement === 1 && (
					<>
						<CheckboxRadioInput
							type='checkbox'
							defaultChecked={props.response.allowedForPublicUse}
							name='allowedForPublicUseCheckbox'
							question={parseHtml(props.template.consentForPublicUseText)}
							onClick={() => props.updateResponse("allowedForPublicUse", !props.response.allowedForPublicUse)}
						/>
						<br />
					</>
				)}

				<div className='clearfix'>
					<NextButton
						className='pull-right btn'
						onClick={() => {
							appInsights.trackEvent({ name: "Commented", properties: props.response });
							props.template.enableFurtherQuestions && !props.template.questionFirst
								? props.toggleNextSection()
								: props.sendFinalResponse();
						}}
						style={{ background: props.template.colorScheme }}
						disabled={props.sending}
						loading={props.sending}
					>
						{props.sending
							? languages.SUBMITTING[language]
							: props.template.enableFurtherQuestions && !props.template.questionFirst
							? languages.NEXT[language]
							: languages.SUBMIT[language]}
					</NextButton>
				</div>
			</div>
		</>
	);
}

export default CommentPage;
