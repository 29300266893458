const getLogoAlign = (float) => {
	float = float ?? "center";
	switch (float) {
		case "left":
			return "start";
		case "right":
			return "end";
		default:
			return "center";
	}
};

const getLogoWidth = (px) => {
	px = px ?? 120;
	//the max width of the survey is 718px, but the max width saved in templates is 550 px
	//convert the 550 base value to a 700 base value
	return (718 / 550) * px;
};

const SurveyHeader = ({ template }) => {
	const logoUrl = template.logoURL;
	const logoAlign = getLogoAlign(template.logoAlign);
	const logoWidth = getLogoWidth(template.logoWidth);
	const colorScheme = template.colorScheme;
	return (
		<div
			style={{ width: "100%", display: "flex", alignItems: logoAlign, flexDirection: "column", marginBottom: "1rem" }}
		>
			<img style={{ maxWidth: "100%" }} src={logoUrl} alt='Company logo' width={logoWidth} />
			<div style={{ backgroundColor: colorScheme, height: "4px", marginTop: "20px", width: "100%" }} />
		</div>
	);
};

export default SurveyHeader;
