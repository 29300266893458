let config = window.MyConfig;

const setLocalValue = (key, placeHolder, newValue) => {
	//if value is not placeholder (pipeline variable was set), ignore local value
	if (config[key] === placeHolder) config[key] = newValue;
};

if (process.env.REACT_APP_API_URL && process.env.REACT_APP_CONNECTIONSTRING) {
	setLocalValue("REACT_APP_API_URL", "#{REACT_APP_API_URL}#", process.env.REACT_APP_API_URL);
	setLocalValue("REACT_APP_CONNECTIONSTRING", "#{REACT_APP_CONNECTIONSTRING}#", process.env.REACT_APP_CONNECTIONSTRING);
}

if (process.env.REACT_APP_TURNSTILE_SITE_KEY) {
	setLocalValue(
		"REACT_APP_TURNSTILE_SITE_KEY",
		"#{REACT_APP_TURNSTILE_SITE_KEY}#",
		process.env.REACT_APP_TURNSTILE_SITE_KEY
	);
}

export default config;
