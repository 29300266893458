import React, { useEffect, useState } from "react";
import { parseHtml } from "../Helper";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import NextButton from "../NextButton";
import languages from "../../Data/Languages.json";
import CheckboxRadioInput from "../CheckboxRadioInput";

function OutroPage(props) {
	const [isConsentChangeSubmitted, setIsConsentChangeSubmitted] = useState(false);
	const language = props.template.language.name;
	const appInsights = useAppInsightsContext();
	useEffect(() => {
		appInsights.trackPageView({ name: "OutroPage.js" });

		if (props.template.autoReload) {
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		}
	}, [appInsights, props.template.autoReload]);
	function setOutroText() {
		if (!props.template.includeCustomOutroText) {
			return props.template.outroText;
		}
		const rating = props.response.rating;
		if (rating <= 6) return props.template.outroDetractorText;
		if (rating === 7 || rating === 8) return props.template.outroPassiveText;
		if (rating > 8) return props.template.outroPromoterText;
	}
	return (
		<>
			<div className={"body"}>
				<h2 className='text-center'>{parseHtml(setOutroText())}</h2>
				{props.template.consentForPublicUse && props.template.consentForPublicUsePlacement === 2 && (
					<div>
						<CheckboxRadioInput
							type='checkbox'
							defaultChecked={props.response.allowedForPublicUse}
							name='allowedForPublicUseCheckbox'
							question={parseHtml(props.template.consentForPublicUseText)}
							onClick={() => {
								props.updateResponse("allowedForPublicUse", !props.response.allowedForPublicUse);
								setIsConsentChangeSubmitted(false);
							}}
						/>
						<br />
						<div className='clearfix'>
							<NextButton
								className='pull-right btn'
								onClick={() => {
									appInsights.trackEvent({ name: "Commented", properties: props.response });
									props.sendFinalResponse(() => setIsConsentChangeSubmitted(true));
								}}
								style={{ background: props.template.colorScheme }}
								disabled={props.sending || isConsentChangeSubmitted}
								loading={props.sending}
							>
								{props.sending
									? languages.SUBMITTING[language]
									: isConsentChangeSubmitted
									? languages.SUBMITTED[language]
									: languages.SUBMIT[language]}
							</NextButton>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default OutroPage;
