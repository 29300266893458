const NoInternetIcon = () => {
	return (
		<svg
			id='noInternetIcon'
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
			style={{ overflow: "initial" }}
		>
			<path
				stroke='#fff'
				fill='#000000'
				fillRule='evenodd'
				d='M23.5226 5.87191C23.5226 9.11488 20.8937 11.7438 17.6507 11.7438C14.4078 11.7438 11.7788 9.11488 11.7788 5.87191C11.7788 2.62894 14.4078 0 17.6507 0C20.8937 0 23.5226 2.62894 23.5226 5.87191ZM16.6721 1.9573L17.0636 6.85056H18.2379L18.6294 1.9573H16.6721ZM17.6507 9.78652C18.1912 9.78652 18.6294 9.34836 18.6294 8.80787C18.6294 8.26737 18.1912 7.82922 17.6507 7.82922C17.1102 7.82922 16.6721 8.26737 16.6721 8.80787C16.6721 9.34836 17.1102 9.78652 17.6507 9.78652ZM12.013 11.3172C11.219 10.4971 10.6027 9.50392 10.2262 8.39968C7.70094 8.70925 5.25788 9.82925 3.32744 11.7597L5.44876 13.881C7.24656 12.0832 9.64456 11.2286 12.013 11.3172ZM10.1256 3.66134C6.43582 4.00788 2.83275 5.59954 0 8.43229L2.12132 10.5536C4.30165 8.37329 7.03302 7.09362 9.85362 6.71125C9.82423 6.4355 9.80916 6.15546 9.80916 5.87191C9.80916 5.10423 9.91964 4.36237 10.1256 3.66134ZM14.4841 17.1106C12.9168 15.5433 10.2456 15.5433 8.67833 17.1106L6.55701 14.9892C9.29583 12.2504 13.8666 12.2504 16.6054 14.9892L14.4841 17.1106ZM11.6301 23.4877C12.9814 23.4877 14.0768 22.3923 14.0768 21.041C14.0768 19.6898 12.9814 18.5944 11.6301 18.5944C10.2789 18.5944 9.18349 19.6898 9.18349 21.041C9.18349 22.3923 10.2789 23.4877 11.6301 23.4877Z'
				clipRule='evenodd'
			/>
		</svg>
	);
};

export default NoInternetIcon;
