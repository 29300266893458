import React, { useEffect, useRef, useState } from "react";
import languages from "../../Data/Languages.json";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ColorSchemes from "../../Data/ColorSchemes.json";
import NextButton from "../NextButton";
import { parseHtml } from "../Helper";
import CheckboxRadioInput from "../CheckboxRadioInput";

function FollowUpQuestionsPage(props) {
	const appInsights = useAppInsightsContext();
	useEffect(() => {
		appInsights.trackPageView({ name: "FollowUpQuestionsPage.js" });
	}, [appInsights]);
	const language = props.template.language.name;
	function formatQuestionIfJson(question) {
		try {
			const res = JSON.parse(question);
			return res;
		} catch {
			return question;
		}
	}

	const choicesRefs = useRef([null, null, null, null, null]);
	const choicesWidths = useRef([null, null, null, null, null]);
	const extraQuestionWrapperRefs = useRef([null, null, null, null, null]);
	const [hasOverflow, setHasOverflow] = useState(false);

	useEffect(() => {
		const checkOverflow = () => {
			let overflowDetected = false;

			for (let index = 0; index < choicesRefs.current.length; index++) {
				const choice = choicesRefs.current[index];
				if (choice) {
					const wrapper = extraQuestionWrapperRefs.current[index];

					if (choicesWidths.current[index] < choice.clientWidth) {
						choicesWidths.current[index] = choice.clientWidth;
					}

					if (choicesWidths.current[index] >= wrapper?.clientWidth) {
						overflowDetected = true;
						break; // Exit the loop
					}
				}
			}

			setHasOverflow(overflowDetected);
		};

		// Initial check
		checkOverflow();

		// Check on window resize
		window.addEventListener("resize", checkOverflow);

		// Cleanup
		return () => {
			window.removeEventListener("resize", checkOverflow);
		};
	}, []);

	return (
		<div className={"body"}>
			{props.template.enableFurtherQuestionsHeaderText && (
				<div className='furtherQuestionsHeaderText'>{parseHtml(props.template.enableFurtherQuestionsHeaderText)}</div>
			)}
			<div id='furtherQuestionsWrapper'>
				{[
					props.template.question1
						? {
								question: formatQuestionIfJson(props.template.question1),
								enableRating: props.template.enableRatingForQuestion1,
						  }
						: null,
					props.template.question2
						? {
								question: formatQuestionIfJson(props.template.question2),
								enableRating: props.template.enableRatingForQuestion2,
						  }
						: null,
					props.template.question3
						? {
								question: formatQuestionIfJson(props.template.question3),
								enableRating: props.template.enableRatingForQuestion3,
						  }
						: null,
					props.template.question4
						? {
								question: formatQuestionIfJson(props.template.question4),
								enableRating: props.template.enableRatingForQuestion4,
						  }
						: null,
					props.template.question5
						? {
								question: formatQuestionIfJson(props.template.question5),
								enableRating: props.template.enableRatingForQuestion5,
						  }
						: null,
				].map((q, key) => {
					const answer = "answer" + (key + 1);
					if (!q) return "";
					return typeof q.question === "object" ? (
						//multiple choice
						<div key={key} ref={(el) => (extraQuestionWrapperRefs.current[key] = el)}>
							<div className='extra-question'>{q.question.question}</div>
							<div
								className={`extra-question-choices ${hasOverflow ? "overflow" : ""}`}
								ref={(el) => (choicesRefs.current[key] = el)}
							>
								{q.question.choices?.map((c, index) => {
									return (
										<CheckboxRadioInput
											key={index}
											type={q.question.allowMultipleAnswers ? "checkbox" : "radio"}
											question={c}
											name={(q.question.allowMultipleAnswers ? "checkbox" : "choice") + key}
											index={index}
											onClick={(e) => {
												props.setMultipleChoice(e, answer, c);
											}}
										/>
									); //title
								})}
							</div>
						</div>
					) : //rating
					q.enableRating ? (
						<div key={key}>
							<div className='extra-question'>{q.question}</div>
							<div className='rating'>
								{props.ratings &&
									props.ratings.map((r, ratingKey) => {
										const currentAnswer = parseInt(props.response[answer]);
										return (
											<div key={ratingKey}>
												<div
													className='button-link'
													onClick={() => {
														props.updateResponse(answer, r + "");
													}}
													style={{
														backgroundColor:
															r === currentAnswer
																? ""
																: props.template.htmlTemplateId === 1
																? (r < 7 && ColorSchemes.DETRACTOR) ||
																  ((r === 7 || r === 8) && ColorSchemes.PASSIVE) ||
																  (r > 8 && ColorSchemes.PROMOTER)
																: props.template.colorScheme,
														borderColor: r === currentAnswer ? props.template.colorScheme : "",
													}}
												>
													<p
														style={{ color: r === currentAnswer ? props.template.colorScheme : "" }}
														className='rating-figure'
													>
														{r}
													</p>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					) : (
						//text
						<div key={key}>
							<div className='extra-question'>{q.question}</div>
							<textarea
								onChange={(e) => props.updateResponse(answer, e.target.value)}
								rows='2'
								height='45px'
							></textarea>
						</div>
					);
				})}
			</div>
			<br />

			<div style={{ display: "inline-block", width: "100%" }}>
				{props.allowPreviousButton && (
					<div className='clearfix' style={{ float: "left" }}>
						<button
							className='pull-right btn'
							onClick={() => {
								appInsights.trackEvent({ name: "Back to comment page", properties: props.response });
								props.togglePreviousSection();
							}}
							style={{ background: props.template.colorScheme }}
							disabled={props.sending}
						>
							{languages.PREVIOUS[language]}
						</button>
					</div>
				)}
				<div className='clearfix' style={{ float: "right" }}>
					<NextButton
						className='pull-right btn'
						onClick={() => {
							appInsights.trackEvent({ name: "More Answers", properties: props.response });
							props.template.questionFirst ? props.toggleNextSection() : props.sendFinalResponse();
						}}
						style={{ background: props.template.colorScheme }}
						disabled={props.sending}
						loading={props.sending}
					>
						{props.questionFirst
							? languages.NEXT[language]
							: props.sending
							? languages.SUBMITTING[language]
							: languages.SUBMIT[language]}
					</NextButton>
				</div>
			</div>
		</div>
	);
}

export default FollowUpQuestionsPage;
