import { useEffect, useState } from "react";
import LoadingButton from "../components/LoadingButton";
import NoInternetIcon from "../Images/NoInternetIcon";

const NextButton = ({ isLoading = false, ...rest }) => {
	const [online, setOnline] = useState(navigator.onLine);

	useEffect(() => {
		function handleOnline() {
			setOnline(true);
		}

		function handleOffline() {
			setOnline(false);
		}

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	return (
		<>
			<LoadingButton
				{...rest}
				disabled={rest.disabled || !online}
				style={rest.style ? { ...rest.style, display: "flex" } : { display: "flex" }}
				type='text'
				className={`${rest.className ?? ""} next-button`}
			>
				{rest.children}
				{!online && (
					<div style={{ margin: "auto", marginLeft: ".5rem", display: "flex" }}>
						<NoInternetIcon />
					</div>
				)}
			</LoadingButton>
		</>
	);
};
export default NextButton;
