import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import config from "./config"

const browserHistory = createBrowserHistory({ basename: '' });
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: config.REACT_APP_CONNECTIONSTRING,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();