import React, {useEffect} from 'react';
import { parseHtml } from '../Helper';
import { useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
function UnsubscribedPage(props){
    const appInsights = useAppInsightsContext();
    useEffect(()=>{appInsights.trackPageView({name: "UnsubscribedPage.js"});},[appInsights])
    return <>
        <div className="body text-center">
            <div>
                <p style={{fontSize: "1.3em", whiteSpace: "pre-wrap"}} id="uText">
                    {parseHtml(props.template.unsubscribeText)}
                </p>
            </div>
        </div>
    </>
}

export default UnsubscribedPage;