import React, { useEffect } from "react";
import Languages from "../../Data/Languages.json";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
function AlreadyAnsweredPage(props) {
	const appInsights = useAppInsightsContext();
	useEffect(() => {
		appInsights.trackPageView({ name: "AlreadyAnsweredPage.js" });
	}, [appInsights]);
	const language = props.template.language.name;
	return (
		<div className={"body"}>
			<div>
				<h2>{Languages.ALREADY_ANSWERED[language]}</h2>
			</div>
		</div>
	);
}

export default AlreadyAnsweredPage;
