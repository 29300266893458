// material
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

const Wrapper = styled("div")({
	height: "100%",
	width: "100%",
	top: 0,
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	overflow: "hidden",
});

const PRIMARY_WHITE = "#fff";
const PRIMARY_BLACK = "#000";

const isDarkMode = false;
const color = isDarkMode ? PRIMARY_WHITE : PRIMARY_BLACK;
const colorContrast = !isDarkMode ? PRIMARY_WHITE : PRIMARY_BLACK;

const Logo = () => {
	return (
		<Box sx={{ width: 50, height: 50 }}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='100%'
				height='100%'
				viewBox='158.91001892089844, 140.35000610351562, 269.0599365234375, 306.1600036621094'
			>
				<g fill={color} fillRule='evenodd' stroke='none' strokeWidth='1'>
					<path
						className='cls-2'
						d='M365.89,221.53V363.67a20.76,20.76,0,1,1-41.48,1.65h.14V223.17a82.82,82.82,0,1,0-165.64,0V345.29a20,20,0,0,0,20,20h62.79V303.27H221v-80.1a20.79,20.79,0,1,1,41.55,0v21.52l-.22,119a82.82,82.82,0,1,0,165.64,0V221.53Z'
					/>
				</g>
			</svg>
		</Box>
	);
};

const LOADING_SCREEN_ANIMATION_HEIGHT = "120px";

const LoadingScreen = () => {
	return (
		<Wrapper>
			<motion.div
				initial={{ rotateY: 0 }}
				animate={{ rotateY: 360 }}
				transition={{
					duration: 2,
					ease: "easeInOut",
					repeatDelay: 1,
					repeat: Infinity,
				}}
				style={{ zIndex: 10 }}
			>
				<Logo sx={{ width: 64, height: 64 }} />
			</motion.div>
			<Box
				component={motion.div}
				animate={{
					scale: [1.2, 1, 1, 1.2, 1.2],
					rotate: [270, 0, 0, 270, 270],
					opacity: [0.25, 1, 1, 1, 0.25],
					borderRadius: ["25%", "25%", "50%", "50%", "25%"],
				}}
				transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
				sx={{
					width: 100,
					height: 100,
					borderRadius: "25%",
					position: "absolute",
					border: (theme) => `solid 3px ${alpha(color, 0.24)}`,
					backgroundColor: colorContrast,
				}}
			/>

			<Box
				component={motion.div}
				animate={{
					scale: [1, 1.2, 1.2, 1, 1],
					rotate: [0, 270, 270, 0, 0],
					opacity: [1, 0.25, 0.25, 0.25, 1],
					borderRadius: ["25%", "25%", "50%", "50%", "25%"],
				}}
				transition={{
					ease: "linear",
					duration: 3.2,
					repeat: Infinity,
				}}
				sx={{
					width: LOADING_SCREEN_ANIMATION_HEIGHT,
					height: LOADING_SCREEN_ANIMATION_HEIGHT,
					borderRadius: "25%",
					position: "absolute",
					border: (theme) => `solid 8px ${alpha(color, 0.24)}`,
					backgroundColor: colorContrast,
				}}
			/>
		</Wrapper>
	);
};

export default LoadingScreen;
